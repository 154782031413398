<template>
  <div id="aboutYanfa clearfix">
    <!--头部导航-->
    <div style="z-index:9999;position:fixed;top:0;left: 0;">
      <baseNav></baseNav>
    </div>

    <!--内容-->
    <div class="aboutYanfaCon clearfix">
      <!--内容头部-->
      <div class="commenHead aboutYanfaHead clearfix">
        <div class="about_tit">
          <p  v-if="yanfaData.title&&yanfaData.title.length>0" class="animated bounceInLeft" v-html="yanfaData.title[0].title"></p>
        </div>
        <div class="about_tit_text">
<!--          <p class="animated bounceInLeft">专注于激光介入医疗器械及相关<br>-->
<!--            技术的研发</p>-->
        </div>
        <div class="about_img">
          <div class="right_grid ">
            <img src="../assets/images/pic_grid_r.png" alt="">
          </div>
          <div class="left_grid " style="height: auto;">
            <img src="../assets/images/pic_grid_l.png" alt="">
          </div>
        </div>
      </div>
      <!--公司介绍-->
      <div class="yf_introduce clearfix">
        <div class="backimg">
          <img src="../assets/images/pic_blue_light.png" alt="">
        </div>

        <div class="intro_cont">
          <p v-if="yanfaData.content&&yanfaData.content.length>0">
            <span  v-for="(item,index) in yanfaData.content" :key="index" v-html="item.content"></span>
          </p>
          </div>
        <div class="yf_img">
            <img src="../assets/images/pic_structure.svg" alt="">
        </div>

      </div>
      <!--创新成果-->
      <div class="achievements">
<!--        星星-->
        <div class="back_plus"></div>

        <div class="topLine"></div>

        <p class="tit_text" v-if="yanfaData.project_title&&yanfaData.project_title.length>0" v-html="yanfaData.project_title[0].title"></p>
        <!--奖杯-->
        <div class="back_achive"></div>
        <ul class="achievements_cont" v-if="yanfaData.project&&yanfaData.project.length>0">
         <li v-for="(item,index) in yanfaData.project" :key="index">
           <span class="years">{{item.title}}</span>
           <span class="years_t">{{item.content}}</span>
         </li>
        </ul>

      </div>
      <!-- footer-->
      <Copyright></Copyright>
    </div>

  </div>
</template>

<script>
import baseNav from "./baseNav";
import Copyright from "@/component/Copyright";
import {GetIndexDoc} from "@/api/api";
export default {
  data(){
    return{
      yanfaData:[],
    }
  },
  mounted() {
    //获取关于研发页面banner数据
    this.toGetIndexDoc(3)
  },
  methods:{
    //获取关于研发页面banner数据
    toGetIndexDoc(data){
      let _this=this
      GetIndexDoc(data)
          .then(r=>{
            let res=r.data
            if(res.status!=1){
              _this.yanfaData=[]
              return
            }
            let apiIndex=res.data
            _this.yanfaData=apiIndex
          })
    }

  },
  components: {
    baseNav,
    Copyright
  }

}
</script>
<style lang="less" scoped>
#aboutYanfa {
  background-color: #071D42;
}
.aboutYanfaCon {
  padding-top: 13.888vw;
  background-color: #071D42;
  width: 100%;

  .aboutYanfaHead{
    background: url("../assets/images/pic_banner_creativity.png") no-repeat -56.94vw 1.666vw;
    background-size: 180%;
    padding-top:15.277vw;
  }

  .yf_introduce{
    position: relative;
    padding:0 6.666vw;
    .backimg{
      width: 60%;
      height: 131.388vw;
      position: absolute;
      left: 0;
      top:55.555vw;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .yf_img{
      width: 100%;
      img{
        width: 100%;
        position: relative;
      }
    }



  }
  //创新成果
  .achievements{
    position: relative;
    margin-top: 22.222vw;
    padding:0 6.666vw;
    padding-bottom:13.888vw;
    .back_plus{
      width: 63%;
      height: 29.722vw;
      position: absolute;
      right: 0;
      top:-22.222vw;
      opacity: 0.8;
      background: url(../assets/images/pic_plus.png) no-repeat right top;
      background-size: cover;
    }
    .achievements_cont{
      min-height: 145vw;
      position: relative;
      li{
        width: 100%;
        margin-bottom:3.333vw;
        span{
          display: inline-block;

        }
        .years{
          vertical-align: top;
            width: 16%;
            font-size: 3.333vw;
            line-height: 5vw;
            color:#20BFD0;
            font-weight: 500;
        }
        .years_t{
          width: 84%;
          line-height: 5vw;
          font-size: 3.333vw;
          height: 6.666vw;
          color: #bbb;
          font-weight: 400;
          text-align: justify;
        }
      }
    }

  }
  .back_achive{
    width: 66.666vw;
    height: 133.333vw;
    position: absolute;
    left: 0;
    top:47.222vw;
    opacity: 0.4;
    background: url("../assets/images/pic_achievement.png") no-repeat right top;
    background-size: cover;
  }
}
</style>